import { useTranslation } from 'react-i18next';
import { AiOutlineHeart } from 'react-icons/ai';
import { MdUpdate } from 'react-icons/md';
import CVFooter from '../../CVFooter/CVFooter';
import flagEN from './../../../assets/icons/gb-eng.svg';
import flagIT from './../../../assets/icons/it.svg';

const BetaSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className='cv-main'>
        <article>
          <div className='content'>
            <h3>{t('cv.education.title')}</h3>
            <h5>{t('cv.education.graduation.title')}</h5>
            <p>{t('cv.education.graduation.subtitle')}</p>
            <h4>{t('cv.education.cisco.title')}</h4>
            <ul>
              <li>
                {t('cv.education.cisco.items.0.label')}
                <span>{t('cv.education.cisco.items.0.date')}</span>
              </li>
              <li>
                {t('cv.education.cisco.items.1.label')}
                <span>{t('cv.education.cisco.items.1.date')}</span>
              </li>
              <li>
                {t('cv.education.cisco.items.2.label')}
                <span>{t('cv.education.cisco.items.2.date')}</span>
              </li>
            </ul>
            <h4>
              {t('cv.education.boolean.title')}
              <span>{t('cv.education.boolean.date')}</span>
            </h4>
            <p>{t('cv.education.boolean.text-content')}</p>
            <h4>
              {t('cv.education.data-science.title')}
              <span>{t('cv.education.data-science.date')}</span>
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.education.data-science.text-content.0'),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.education.data-science.text-content.1'),
              }}
            />
            <h4>
              {t('cv.education.nlp.title')}
              <span>(07/23 - 09/23)</span>
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.education.nlp.text-content.0'),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.education.nlp.text-content.1'),
              }}
            />
            <h4>
              {t('cv.education.k8s.title')}
              <span>(10/23 - 11/23)</span>
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.education.k8s.text-content.0'),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.education.k8s.text-content.1'),
              }}
            />
            <h4>
              {t('cv.education.computer-vision.title')}
              <span>(04/24 - 05/24)</span>
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.education.computer-vision.text-content'),
              }}
            />
            <div className='privacy'>
              <p>{t('cv.consent')}</p>
              <p>
                <MdUpdate fill='#4AF626' /> Last update on 18-01-2024
              </p>
            </div>
          </div>
        </article>
        <article>
          <div className='content'>
            <h3>{t('cv.rottigni-tech.title')}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.rottigni-tech.text-content'),
              }}
            />
            <ul>
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.rottigni-tech.list.0'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.rottigni-tech.list.1'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.rottigni-tech.list.2'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.rottigni-tech.list.3'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.rottigni-tech.list.4'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.rottigni-tech.list.5'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.rottigni-tech.list.6'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.rottigni-tech.list.7'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.rottigni-tech.list.8'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.rottigni-tech.list.9'),
                }}
              />
            </ul>
            {/*<h3>{t('cv.open-source.title')}</h3>
            <p>{t('cv.open-source.text-content')}</p>*/}
            <h3 className='mt-2'>
              I <AiOutlineHeart fill='#4AF626' /> DOCKER
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.docker.text-content.0'),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.docker.text-content.1'),
              }}
            />
            <aside>
              <div>
                <h3>{t('cv.contacts.title')}</h3>
                <ul>
                  <li>{t('cv.contacts.title')}</li>
                  <li>+39 3806947004</li>
                  <li>linkedIn/LorenzoRottigni</li>
                  <li>github.com/LorenzoRottigni</li>
                  <li>gitlab.com/LorenzoRottigni</li>
                  <li>lorenzo@rottigni.net</li>
                </ul>
              </div>
              <div>
                <h3>{t('cv.info.title')}</h3>
                <ul>
                  <li>{t('cv.info.items.0')}</li>
                  <li>{t('cv.info.items.1')}</li>
                  <li>{t('cv.info.items.2')}</li>
                  <li>{t('cv.info.items.3')}</li>
                  <li className='img-item'>
                    {t('cv.info.items.4')}
                    <img
                      src={flagIT}
                      alt='italian flag'
                      className='img-item'
                      width='30'
                      height='20'
                    />
                    -
                    <img
                      src={flagEN}
                      alt='italian flag'
                      className='img-item'
                      width='30'
                      height='20'
                    />
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </article>
        <div className='pagination'>
          <span>2</span>/2
        </div>
      </div>
      <CVFooter />
    </section>
  );
};

export default BetaSection;
