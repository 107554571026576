import React from 'react';
import Logo from './../Logo/Logo';
import './Footer.sass';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className='sign'>
        Coded with
        <span>&hearts;</span>
        by
        <a
          href='https://portfolio.rottigni.tech'
          target='_blank'
          rel='noreferrer'
        >
          Lorenzo Rottigni
        </a>
      </div>
      <div className='logo'>
        <Logo size={125} color='#4AF626' />
      </div>
    </footer>
  );
};

export default Footer;
