import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import flagEN from './../../assets/icons/gb-eng.svg';
import flagIT from './../../assets/icons/it.svg';
import { getHueRotation } from './../../utils/hue-rotation';
import './Header.sass';

const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const hueRotation = getHueRotation(searchParams);
  const downloadLink =
    i18n.language.toLowerCase() === 'it'
      ? 'https://storage.rottigni.tech/fs/documents/cv/v5/cv-lorenzo-rottigni-it-min.pdf'
      : 'https://storage.rottigni.tech/fs/documents/cv/v5/cv-lorenzo-rottigni-en-min.pdf';
  return (
    <header>
      <a href={downloadLink} download>
        Download PDF
      </a>

      <ul>
        <li>
          <a href='/it/' style={{ filter: `hue-rotate(-${hueRotation})` }}>
            <img src={flagIT} alt='italian flag' width='35' height='25' />
          </a>
        </li>
        <li className='separator' />
        <li>
          <a href='/' style={{ filter: `hue-rotate(-${hueRotation})` }}>
            <img src={flagEN} alt='english flag' width='35' height='25' />
          </a>
        </li>
      </ul>
    </header>
  );
};

export default Header;
