import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from './../Logo/Logo';
import './CVFooter.sass';

const CVFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <aside className='cv-footer'>
      <Logo size={50} color='#4AF626' />
      <ul>
        <li>
          <a href={t('cv.footer.cv')} target='_blank' rel='noreferrer'>
            {t('cv.footer.cv')}
          </a>
          <span />
        </li>
        <li>
          <a href={t('cv.footer.portfolio')} target='_blank' rel='noreferrer'>
            {t('cv.footer.portfolio')}
          </a>
          <span />
        </li>
      </ul>
    </aside>
  );
};

export default CVFooter;
