export enum Theme {
  'amber' = 'amber',
  'amethyst' = 'amethyst',
  'enterprise' = 'enterprise',
  'frost' = 'frost',
  'militar' = 'militar',
  'neon' = 'neon',
  'teal' = 'teal',
}

/* The only way without adding redux */
export const getHueRotation = (searchParams: URLSearchParams): string => {
  let hueRotation = searchParams.get('hue-rotation')
    ? `${searchParams.get('hue-rotation')}deg`
    : '0deg';
  const theme: Theme = (searchParams.get('theme') as Theme) || null;

  if (theme) {
    const themes: { [x in keyof typeof Theme]: string } = {
      amber: '290deg',
      amethyst: '155deg',
      enterprise: '105deg',
      frost: '105deg',
      militar: '20deg',
      neon: '225deg',
      teal: '40deg',
    };
    hueRotation = themes[theme as unknown as keyof typeof Theme];
  }

  return hueRotation;
};
