// Layout/Default.tsx
import React, { useEffect, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useSearchParams } from 'react-router-dom';
import './Default.sass';

interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const isPuppeteer = typeof searchParams.get('puppeteer') === 'string';
  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(14, 22, 30, 0.95)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#0e161e',
      width: '90%',
      maxWidth: '400px',
    },
  };

  const scrollbarStyles = {
    '::WebkitScrollbar': {
      width: '.4rem',
      borderRadius: '30px',
      backgroundColor: '#0e161e',
    },
    '::WebkitScrollbarTrack': {
      background: 'transparent',
    },
    '::WebkitScrollbarThumb': {
      backgroundColor: '#4AF626',
    },
  } as React.CSSProperties;

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    Modal.setAppElement('#root');
    if (typeof window === 'undefined') return;
    if (isPuppeteer) return;
    if (window.innerWidth > 793) return;
    openModal();
  }, [isPuppeteer]);

  return (
    <div
      style={!isPuppeteer ? scrollbarStyles : {}}
      className={isPuppeteer ? 'default-layout' : 'default-layout bg-gradient'}
    >
      {children}
      <Modal
        style={modalStyles}
        isOpen={modalIsOpen}
        contentLabel='Example Modal'
      >
        <div className='mobile-warning-modal'>
          <h2>{t('modals.mobile-warning.title')}</h2>
          <p>{t('modals.mobile-warning.text-content.0')}</p>
          <p>{t('modals.mobile-warning.text-content.1')}</p>
          <button onClick={closeModal}>{t('modals.mobile-warning.cta')}</button>
        </div>
      </Modal>
    </div>
  );
};

export default DefaultLayout;
