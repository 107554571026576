import React from 'react';
import { useSearchParams } from 'react-router-dom';
import AlphaSection from '../../components/Sections/Alpha/Alpha';
import BetaSection from '../../components/Sections/Beta/Beta';
import './Home.sass';

const HomePage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const page: null | string = searchParams.get('page');

  return (
    <>
      {page === null || parseInt(page) > 2 ? (
        <div className='home-page'>
          <AlphaSection />
          <BetaSection />
        </div>
      ) : parseInt(page) === 1 ? (
        <div className='home-page'>
          <AlphaSection />
        </div>
      ) : (
        <div className='home-page'>
          <BetaSection />
        </div>
      )}
    </>
  );
};

export default HomePage;
