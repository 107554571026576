import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import WebFont, { type Config as WebfontConfig } from 'webfontloader';
import './App.sass';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import SD from './config/sd.json';
import DefaultLayout from './layouts/Default';
import HomePage from './pages/Home/Home';
import { getHueRotation } from './utils/hue-rotation';

function App() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const isPuppeteer = typeof searchParams.get('puppeteer') === 'string';
  const hueRotation = getHueRotation(searchParams);
  const header = !isPuppeteer ? <Header /> : null;
  const footer = !isPuppeteer ? <Footer /> : null;

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['M PLUS 1 Code'],
      },
    } as WebfontConfig);
  }, []);

  useEffect(() => {
    const currentLanguage = location.pathname.includes('/it') ? 'it' : 'en';
    i18n.changeLanguage(currentLanguage);
  }, [location.pathname, i18n]);

  return (
    <HelmetProvider>
      <div className='app' style={{ filter: `hue-rotate(${hueRotation})` }}>
        <Helmet>
          <link
            rel='canonical'
            href={`https://cv.rottigni.tech${
              i18n.language.toLowerCase() === 'en' ? '/' : '/it/'
            }`}
          />
          <link
            rel='alternate'
            hrefLang={i18n.language.toLowerCase() === 'en' ? 'it' : 'en'}
            href={`https://cv.rottigni.tech${
              i18n.language.toLowerCase() === 'en' ? '/it/' : '/'
            }`}
          />
          <script type='application/ld+json'>
            {JSON.stringify(SD, null, 2)}
          </script>
        </Helmet>
        {header}
        <main>
          <DefaultLayout>
            <HomePage />
          </DefaultLayout>
        </main>
        {footer}
      </div>
    </HelmetProvider>
  );
}

export default App;
